import InvoiceCellRenderer from '../../utility/agGrid/InvoiceCellRenderer';
import ResendInviteRenderer from '../../utility/agGrid/ResendInviteRenderer';
import {ReactComponent as LoadingIcon} from '../../media/icon-loading.svg';
import {ReactComponent as AddIcon} from '../../media/icon-add.svg';
import {ReactComponent as CloseIcon} from '../../media/icon-close.svg';
import {addRewardsAPI} from '../../../api';
import {useStore} from '../../../store';
import useBreadcrumbs from 'application/ui/utility/routing/useBreadcrumbs';
import DataGrid from 'application/ui/utility/agGrid/DataGrid';
import {
  getAccountDetailsAPI,
  getSubAccountsAPI,
  getResidentListAPI,
  getInvoiceListAPI
} from '../../../api';
import React, {
  useState,
  useEffect,
  ChangeEvent,
  FormEvent,
  Dispatch,
  SetStateAction
} from 'react';
import {useParams} from 'react-router-dom';
import {TextField, Checkbox, FormControlLabel} from '@mui/material';

type FacSubAccount = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  dateCreated: string;
};

type FacResident = {
  firstName: string;
  lastName: string;
  sex: 'M' | 'F',
  clothingSize: string;
  shoeSize: string;
  budget: string;
};

type FacInvoice = {
  id: string;
  date: Date;
  resident: string;
  total: string;
  invoiceId: number;
};


//Facility Grids Section
type FacDetailGridProps = {
  type: 'sub' | 'res' | 'inv';
  rowData: Array<FacSubAccount> | Array<FacResident> | Array<FacInvoice>;
};

/**
 * Grid component for the Facility Detail Page.
 * Creates 3 types of grids depending on the given props.
 * @param type Determines the appropriate data structure for the grid.
 * sub = subaccounts, res = residents, inv = invoices.
 * @param rowData
 * @returns Grid component
 */
const FacDetailGrid = ({type, rowData}: FacDetailGridProps) => {
  const defaultColDef = {
    resizable: true,
    sortable: true,
    minWidth: 100,
  };
  const columnDefsSub = [
    {field: 'dateCreated', headerName: 'Date Created', filter: 'agDateColumnFilter', minWidth: 50, maxWidth: 150},
    {field: 'title', headerName: 'Title', filter: 'agTextColumnFilter'},
    {field: 'firstName', headerName: 'First Name', filter: 'agTextColumnFilter'},
    {field: 'lastName', headerName: 'Last Name', filter: 'agTextColumnFilter'},
    {field: 'email', headerName: 'Email', filter: 'agTextColumnFilter'},
    {field: 'invite', headerName: 'Action', cellRenderer: ResendInviteRenderer}
  ];
  const columnDefsRes = [
    {field: 'firstName', headerName: 'First Name', filter: 'agTextColumnFilter'},
    {field: 'lastName', headerName: 'Last Name', filter: 'agTextColumnFilter'},
    {field: 'sex', headerName: 'Sex', filter: 'agTextColumnFilter', minWidth: 50, maxWidth: 100},
    {field: 'clothingSize', headerName: 'Clothing Size', filter: 'agTextColumnFilter'},
    {field: 'shoeSize', headerName: 'Shoe Size', filter: 'agTextColumnFilter'},
    {field: 'budget', headerName: 'Budget', filter: 'agNumberColumnFilter'}
  ];
  const columnDefsInv = [
    {field: 'date', headerName: 'Date', filter: 'agDateColumnFilter', minWidth: 50, maxWidth: 125},
    {field: 'invoiceId', headerName: 'Invoice ID', filter: 'agNumberColumnFilter', cellRenderer: InvoiceCellRenderer,  minWidth: 50, maxWidth: 125},
    {field: 'resident', headerName: 'Resident', filter: 'agTextColumnFilter', minWidth: 100, maxWidth: 300},
    {field: 'total', headerName: 'Total', filter: 'agNumberColumnFilter'}
  ];

  let curColDefs;
  
  switch (type) {
    case 'sub':
      curColDefs = columnDefsSub;
      break;
      
    case 'res':
      curColDefs = columnDefsRes;
      break;

    default:
      curColDefs = columnDefsInv;
      break;
    }

  return <DataGrid
    rowData={rowData}
    defaultColDef={defaultColDef}
    columnDefs={curColDefs}
    paginationPageSize={5}
    domLayout='autoHeight'
  />;
};


//Rewards Popup Section
type FacRewards = {
  rewards: string;
  rewardsExpiration: string;
};

type RewardsPopupProps = {
  userId: string;
  setToggleRewardPopup: Dispatch<SetStateAction<boolean>>;
  setRewards: Dispatch<SetStateAction<FacRewards>>;
};

/**
 * Popup component that allows an admin to change a facility's reward amount/expiration.
 * @param userId
 * @param setToggleRewardPopup Action that triggers this component
 * @param setRewards Action that updates rewards amount/expiration
 * @returns Rewards popup component
 */
const RewardsPopup = ({userId, setToggleRewardPopup, setRewards}: RewardsPopupProps) => {
    const admin = useStore((state) => state.admin);
    const dispatchSetAlert = useStore((state) => state.dispatchSetAlert);
    const [addRewardsNote, setAddRewardsNote] = useState('');
    const [addRewardsAmount, setAddRewardsAmount] = useState('');
    const [isSendingRewardsEmail, toggleIsSendingRewardsEmail] = useState(false);
    
    const onChangeInputRewards = (e: ChangeEvent<HTMLInputElement>) =>
    setAddRewardsAmount(e.target.value);
    
    const submitAddRewards = async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
    
      if (!admin) return dispatchSetAlert({
        status: 'danger',
        header: 'Invalid Credentials',
        text: 'Invalid admin credentials.'
      });
    
      const res = await addRewardsAPI({
        adminID: admin.id,
        userID: userId,
        reward_amount: Number(addRewardsAmount),
        reward_note: addRewardsNote,
        sendEmail: isSendingRewardsEmail ? 1 : undefined
      });
      
      console.log(res);//TESTING

      setRewards({
        rewards: res.data.newRewardAmount.toString(),
        rewardsExpiration: res.data.newRewardExpirationDate
      });
      
      dispatchSetAlert({
        status: 'success',
        header: 'Success',
        text: 'Rewards have been added.'
      });
      
      setAddRewardsAmount('');
      setAddRewardsNote('');
      setToggleRewardPopup(false);
    };
    

  return (
    <div className='rewards_popup rewards_popup--add'>
      <div className='rewards_popup__main'>
        <div className='rewards_popup__banner'>
          <button
            className='rewards_popup__close'
            onClick={() => setToggleRewardPopup(false)}
          >
            <CloseIcon />
          </button>
        </div>
        <form
          className='rewards_popup__prompt'
          onSubmit={submitAddRewards}
        >
          <h3>How much would you like to add?</h3>
          <TextField
            label='Rewards ($)'
            type='number'
            value={addRewardsAmount}
            onChange={onChangeInputRewards}
            required />
          <TextField
            label='Notes'
            value={addRewardsNote}
            onChange={(e) => setAddRewardsNote(e.target.value)} />
          <FormControlLabel
            className='rewards_popup__checkbox'
            label='Send Email'
            control={
              <Checkbox
                name='substitutions'
                checked={isSendingRewardsEmail}
                onChange={() => toggleIsSendingRewardsEmail(!isSendingRewardsEmail)}
              />
            }
          />
          <button
            className='btn btn--primary'
            type='submit'
          >
            Add
          </button>
        </form>
      </div>
    </div>
  );
};


//Facility Detail Page Section
/**
 * Custom hook to manage state and actions for facility information.
 * @param userId 
 * @param setRewards Action that updates reward amount/expiration.
 * This is required on this component because the rewards data originates from the same api as the facility information.
 * @returns Facility info, and loading state to indicate that data is being fetched
 */
const useFacilityInfo = (userId: string, setRewards: Dispatch<SetStateAction<FacRewards>>) => {
  const [isLoading, setIsLoading] = useState(true);
  const [facilityInfo, setFacilityInfo] = useState({
    id: '',
    name: '',
    email: '',
    username: '',
    address: {
      county: '',
      street: '',
      city: '',
      state: '',
      zip: '',
      country: ''
    },
    bom: {
      name: '',
      email: ''
    },
    admin: {
      name: ''
    },
    phone: '',
    fax: '',
    tax: ''
  });

  const getFacilityInfo = async () => {
    const res = await getAccountDetailsAPI(userId);
      setFacilityInfo({
        id: res.data.facPO || 'N/A',
        name: res.data.facName,
        username: res.data.username,
        email: res.data.email,
        address: {
          street: `${res.data.facAddress1} ${res.data.facAddress2}`,
          county: res.data.facCounty,
          city: res.data.facCity,
          state: res.data.facState,
          zip: res.data.facZip,
          country: res.data.facCountry
        },
        bom: {
          name: res.data.facOfficeMgr,
          email: res.data.facOfficeMgrEmail
        },
        admin: {
          name: res.data.facAdmin
        },
        phone: res.data.phoneNumber,
        fax: res.data.facFax,
        tax: res.data.taxrate
      });

    setRewards({
      rewards: res.data.facRewards,
      rewardsExpiration: res.data.facRewardsExpiration
    });

    setIsLoading(false);
  };

  useEffect(() => {getFacilityInfo()}, []);
  
  return {facilityInfo, isLoading};
};
  
/**
 * Custom hook to manage state and actions for facility subaccounts.
 * @param userId 
 * @returns Facility subaccounts, and loading state to indicate that data is being fetched
 */
const useSubAccounts = (userId: string) => {
  const [subAccounts, setSubAccounts] = useState<Array<FacSubAccount>>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getSubAccounts = async () => {
    const res = await getSubAccountsAPI(userId);
    setSubAccounts(res.data.map((sub) => ({
      id: sub.ID,
      firstName: sub.fName,
      lastName: sub.lName,
      email: sub.email,
      title: sub.title,
      dateCreated: sub.createdDate
    })));

    setIsLoading(false);
  };

  useEffect(() => {getSubAccounts()}, []);

  return {subAccounts, isLoading};
};

/**
 * Custom hook to manage state and actions for facility residents.
 * @param userId
 * @returns facility residents, and loading state to indicate that data is being fetched
 */
const useResidents = (userId: string) => {
  const [residents, setResidents] = useState<Array<FacResident>>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getResidents = async () => {
    const res = await getResidentListAPI(Number(userId));
    setResidents(res.data.map((resident) => ({
      firstName: resident.fName,
      lastName: resident.lName,
      sex: resident.sex,
      clothingSize: resident.clothing_size,
      shoeSize: resident.shoe_size,
      budget: resident.budget_amount
    })));

    setIsLoading(false);
  };

  useEffect(() => {getResidents()}, []);
  
  return {residents, isLoading};
};

/**
 * Custom hook to manage state and actions for facility invoices.
 * @param userId 
 * @returns Facility invoices, and loading state to indicate that data is being fetched
 */
const useInvoices = (userId: string) => {
  const [invoices, setInvoices] = useState<Array<FacInvoice>>([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const getInvoices = async () => {
    const res4 = await getInvoiceListAPI(Number(userId));
    setInvoices(res4.data.map((inv) => ({
      id: userId,
      date: inv.date,
      resident: inv.residentFullName,
      total: inv.total_cost,
      invoiceId: inv.invoice_ID
    })));

    setIsLoading(false);
  };

  useEffect(() => {getInvoices()}, []);

  return {invoices, isLoading};
};


type Params = {
  userId: string;
};

/**
 * Page that details a single facility
 * @returns Page component
*/
const FacilityDetail = () => {
  const {userId} = useParams<Params>();
  useBreadcrumbs([
    {
      id: 0,
      name: 'Dashboard',
      path: '/admin/dashboard'
    },
    {
      id: 2,
      name: 'Facilities',
      path: '/admin/lookup/facility'
    }
  ]);
  const [toggleRewardPopup, setToggleRewardPopup] = useState(false);
  const [rewards, setRewards] = useState({
    rewards: '',
    rewardsExpiration: ''
  });
  const {facilityInfo, isLoading: isInfoLoading} = useFacilityInfo(userId, setRewards);
  const {subAccounts, isLoading: isSubLoading} = useSubAccounts(userId);
  const {residents, isLoading: isResLoading} = useResidents(userId);
  const {invoices, isLoading: isInvLoading} = useInvoices(userId);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(
    () => {
      if (
        !isInfoLoading &&
        !isSubLoading &&
        !isResLoading &&
        !isInvLoading
      ) setIsLoading(false);
    }, [isInfoLoading, isSubLoading, isResLoading, isInvLoading]
  );
    
  if (isLoading) return (
    <div className='a-fac-detail'>
      <LoadingIcon />
    </div>
  ); 

  return (
  <>
      <div className='a-fac-detail'>
        <div className='a-fac-detail__info'>
          <h3>Facility Info</h3>
          <ul>
            <li>
              <h5>Facility ID:</h5>
              <p>{facilityInfo.id}</p>
            </li>
            <li>
              <h5>Name:</h5>
              <p>{facilityInfo.name}</p>
            </li>
            <li>
              <h5>Address:</h5>
              <p>{facilityInfo.address.street}</p>
              <p>
                {`${
                  facilityInfo.address.city
                }${
                  facilityInfo.address.county.length > 0 ?
                  ` (${facilityInfo.address.county})` :
                  ''
                }, ${
                  facilityInfo.address.state
                } ${
                  facilityInfo.address.zip
                }`}
              </p>
              <p>{facilityInfo.address.country}</p>
            </li>
            <li>
              <h5>Admin:</h5>
              <p>{facilityInfo.admin.name}</p>
            </li>
            <li>
              <h5>BOM</h5>
              <p>{facilityInfo.bom.name}</p>
            </li>
            <li>
              <h5>BOM's Email:</h5>
              <p>{facilityInfo.bom.email}</p>
            </li>
            <li>
              <h5>Phone:</h5>
              <p>{facilityInfo.phone}</p>
            </li>
            <li>
              <h5>Fax:</h5>
              <p>{facilityInfo.fax}</p>
            </li>
            <li>
              <h5>Email:</h5>
              <p>{facilityInfo.email}</p>
            </li>
            <li>
              <h5>Username:</h5>
              <p>{facilityInfo.username}</p>
            </li>
            <li className='a-fac-detail__rewards'>
              <h5>Rewards:</h5>
              <span>
                <p>{rewards.rewards}</p>
                <button onClick={() => setToggleRewardPopup(true)}>
                  <AddIcon />
                </button>
              </span>
            </li>
            <li>
              <h5>Rewards Exp.</h5>
              <p>{rewards.rewardsExpiration}</p>
            </li>
            <li>
              <h5>Sales Tax:</h5>
              <p>{facilityInfo.tax}</p>
            </li>
          </ul>
        </div>
        <div>
          <h3>Authorized Users</h3>
          <FacDetailGrid
            type='sub'
            rowData={subAccounts}
          />
        </div>
        <div>
          <h3>Resident List</h3>
          <FacDetailGrid
            type='res'
            rowData={residents}
          />
        </div>
        <div>
          <h3>Order History</h3>
          <FacDetailGrid
            type='inv'
            rowData={invoices}
          />
        </div>
      </div>
      {
        toggleRewardPopup &&
        <RewardsPopup
          userId={userId}
          setRewards={setRewards}
          setToggleRewardPopup={setToggleRewardPopup}
        />
      }
    </>
  );
};

export default FacilityDetail;